import React, { Component } from "react";
import Email from "../../Assets/Images/email.svg";
import Pin from "../../Assets/Images/pin.svg";
import Phone from "../../Assets/Images/phone.svg";

/* eslint-disable */
class DesignCentersFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
    };
  }
  static propTypes = {};

  state = {};

  render() {
    // console.log("render DesignCentersFooter");
    // console.log(this.props);
    var phone =
      this.props.data == null ? "416-477-5429" : this.props.data.phone;
    var email =
      this.props.data == null
        ? "service@agmrenovations.com"
        : this.props.data.email;
    var fax = this.props.data == null ? "905-597-5797" : this.props.data.fax;
    let locations =
      this.props.data !== null
        ? this.props.data.location
        : [
            {
              title: "Vaughan",
              address: "45 Tigi Court",
              city: "Concord",
              province: "Ontario",
              postal: "L4K 5E4",
            }
          ];

    return (
      <div
        style={{ backgroundColor: "#2e4659" }}
        className="sticky-nav-top-margin"
      >
        {locations.map((dataOjb, index) => (
          <div className="row design-centers-footer" key={index}>
            <div className="col-md-6" style={{ width: "50%" }}>
              <span style={{ fontSize: "15px" }}>{dataOjb.title} Location</span>
              <br></br>
              <span style={{ fontSize: "13px" }}>{dataOjb.address}</span>
            </div>
            <div className="col-md-2" style={{ width: "15%" }}>
              <a
                href="https://www.houzz.com/professionals/design-build-firms/agm-renovations-pfvwus-pf~126163196?"
                target="_blank"
              >
                <img src={Pin} />
              </a>
            </div>
            <div className="col-md-2" style={{ width: "20%" }}>
              <a href={"mailto:" + email}>
                <img src={Email} />
              </a>
            </div>
            <div className="col-md-2" style={{ width: "15%" }}>
              <a href={"tel:" + phone}>
                <img src={Phone} />
              </a>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default DesignCentersFooter;
